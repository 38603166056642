<template>
  <b-container fluid>
    <bo-page-title />    

    <div class="card">
      <div class="card-body">
        <div class="row mb-4" v-for="(notif, k) in dataList" :key="k">          
          <div class="col-md-1 d-flex justify-content-center">
            <div class="btn btn-info btn-circle" @click="toLocation(notif.an_link)">
              <i class="ti-location-arrow"></i>
            </div>
          </div>

          <div class="col-md-11">
            <div class="mail-contnet ml-3 d-flex align-items-center justify-content-between">
              <div class="left">
                <h5>{{ notif['an_title'] }}</h5>
                <span class="time">{{ notif['an_description'] }}</span>
              </div>

              <div class="right">
                <div class="span time">{{ notif['an_date'] | moment("LL") }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>


        <template v-if="!data['data']">
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
      </div>
      
      <div class="card-footer" v-if="(dataList||[]).length&&data.total>10">
        <template>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <label for="perPage" class="mb-0 mr-2">Show</label>
                <b-form-select
                  size="sm"
                  id="perPage"
                  class="w-auto mx-50"
                  v-model="perPage"
                  :options="Config.dataShownOptions"
                />
              <label for="perPage" class="mb-0 ml-2">items per page</label>
            </div>
            <b-pagination
              class="mb-0"
              v-model="pageNo"
              :per-page="data.per_page"
              :total-rows="data.total"
            />
          </div>
        </template>
      </div>
    </div>
  </b-container>
</template>
<script>
import GlobalVue from '../../libs/Global.vue'

export default {
  extends: GlobalVue,
  data() {
    return {
      data: {}
    }
  },
  mounted() {
    this.apiGet()    
  },
  methods: {
    toLocation(link){
      this.$router.push(link)
    }
  },
  watch:{
    $route(){
      this.apiGet()  
    }
  },
}
</script>